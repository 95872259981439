import React from 'react';
import './TraitSection.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Paper from '@mui/material/Paper';
import MuiAccordion from '@mui/material/Accordion';

function TraitSection({
sectionId,
title1,
title2,
traits,
}) {




const theme = createTheme({
  components: {
    // Name of the component
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
          paddingRight:'0px',
          '@media (min-width: 600px)': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
});



  return (
    
    <>
    <ThemeProvider theme={theme}>
    <Box className='container' id={sectionId} >
       <Grid container direction='column' >
          
          {/* title section */}
          <Grid container  className='innercontainer' >

              <Grid container direction='column' className='title-box' 
                    alignItems='flex-start' justifyContent='center' xs={12} >
                  <Grid item >
                      <Box className={title1.css}>{title1.txt}</Box>    
                      
                  </Grid>
                  <Grid item>
                    <Box className={title2.css}>{title2.txt}</Box>                 
                  </Grid>
              
              
              
              </Grid>
          </Grid>

          <Grid item  >
            <div class='trait-friseup'></div>
            </Grid>
          {/* main content */}
          <Grid container className='trait-content-box'>
           <Grid container  className='innercontainer' 
              justifyContent='center'
              alignItems='center'  pb='50px' pt='50px' >

              {traits.map( (trait,i) => { return (
               <>
                <Grid container item  xs={12} sm={12} md={6} pl='10px'>
                <Grid container item  className='trait-item'
                     alignItems='center'  justifyContent='center'
                     mb='5px' mt={i>traits.length-2 ?'50px':'0px'}   >
                  
                  <Grid  container item xs={10} alignItems='center' >
                        <Grid item >
                        <Box className='trait-trait' display='flex' justifyContent='left' pl='0px'>
                            <img src={trait.icon} className='trait-icon' />
                         </Box>
                        </Grid>
                        <Grid item  pl='20px'>
                         <Box className='normalbold' display='flex' justifyContent='left'>
                            {trait.trait}
                         </Box>                     </Grid>
                  </Grid >

                 <Grid item  xs={2} pr='20px'  >
                     <Box className='trait-number' display='flex' justifyContent='right' >
                        {trait.number}
                      </Box>
                 </Grid>
                </Grid>                                     
                </Grid>          
              </> 
              )})}  

          </Grid>
          </Grid>  
          
          <Grid item  >
            <div class='trait-frisedn'></div>
            </Grid>    
       </Grid>
    </Box>

    </ThemeProvider>
    </>
  );
}

export default TraitSection;