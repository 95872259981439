import React from 'react';
import { useState } from "react";
import './BenefitSection.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import AnimateCC from "react-adobe-animate";

function BenefitSection({
sectionId,
title1,title2,title3,
teasertxt,teaserauthor,
introtxt,
funlabel,funitems,fungif,
lovelabel,loveitems,
nftimg,flagimg,loveimg,
ebtitle,ebsubtitle,
ebitems,
mototxt,motoimg,
philotxt,
}) {

  const [animationObject, getAnimationObject] = useState(null);
  const [paused, setPaused] = useState(false);


const theme = createTheme({
  components: {
    // Name of the component
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
          paddingRight:'0px',
          '@media (min-width: 600px)': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
});




  return (
    
    <>
    <ThemeProvider theme={theme}>
    <Box className='container textselectable' id={sectionId} >
       <Grid container direction='column' >
          
          {/* title section */}
          <Grid container  className='innercontainer' >

              <Grid container direction='column' className='title-box' 
                    alignItems='flex-start' justifyContent='center' xs={12} >
                  <Grid item >
                      <Box className={title1.css}>{title1.txt}</Box>    
                      
                  </Grid>
                  
                  <Grid item>
                    <Box className={title2.css}>{title2.txt}</Box>                 
                  </Grid>
                  
                  <Grid item>
                    <Box className={title3.css} mt='0px'>{title3.txt}</Box>                 
                  </Grid>
              
              
              </Grid>
          </Grid>

          <Grid item>
            <div class='benefit-friseup'></div>
            </Grid>
          {/* main content */}
          <Grid container className='benefit-content-box'>
              <Grid container  className='innercontainer' 
                  justifyContent='center' 
                  alignItems='center'  pb='85px' pt='75px' >
                    
                    {/*intro Block */}
                    <Grid container   alignItems='start' >
                    <Grid item md={12} xs={12} >
                      <Box display='flex' justifyContent='center' className='benefit-teaser intertitle'>
                            {teasertxt}
                          </Box>

                      </Grid>
                      <Grid item md={9} xs={12} >
                      <Box display='flex' justifyContent='center' className='benefit-intro chapeau textjustified '>
                            {introtxt}
                          </Box>

                      </Grid>
                      <Grid item md={3} xs= {12}>
                          <Box display='flex' justifyContent='center' className='benefit-intro-icon' >
                              <img src={nftimg} className='benefit-nftimg'></img>
                          </Box>
                      </Grid>    
                      


                    </Grid>
                    
                    
                    {/* fun Block */}
                    {/* fun label */}
                    <Grid container   alignItems='center' pt='35px' justifyContent={{xs:'center' ,md:'start'}} >
                        <Grid Item>
                                    <Box className='benefit-label  intertitle textcenter' alignContent='center'  >{funlabel}</Box>
                        </Grid>
                    </Grid>
                    {/* fun item & Anim Label */}
                    <Grid container   alignItems='center'   >
                          <Grid container md={6} xs={12}  pt={{xs:'30px',md:'25px'}} pb={{xs:'0px',md:'50px'}} >

                                {funitems.map( (item,i) => { return (
                                <> 
                                <Grid container   alignItems='center' pb={{xs:'15px',md:'25px'}}>
                                    <Grid item xs={2}>
                                    <Box display='flex' justifyContent='center' >
                                          <img src={flagimg} className='benefit-funflag'  ></img>     
                                    </Box>
                                    </Grid>
                                    <Grid item xs={9} className='normal' >
                                      {item.txt}
                                    </Grid>
                                </Grid>
                                </>
                                )})}  
                          </Grid>

                          <Grid item md={6} xs={12} mt={{xs:'0px',md:'-35px'}} pr={{xs:'0px',md:'-10px'}}>
                              <Box display='flex' justifyContent='center' className='benefit-funanim' >
                              {/*
                              <AnimateCC
                              animationName="animbenefits"
                              getAnimationObject={getAnimationObject}
                              paused={paused}
                              />
                                */}
                                 <img src={fungif} className='benefit-fungif'  ></img>     
                              </Box>
                          </Grid>    
                          <Grid item xs={1}></Grid>
                    </Grid>

                    {/* love Block */}
                    <Grid container   alignItems='center' pt='35px' justifyContent={{xs:'center' ,md:'start'}} >
    
                      <Grid Item>
                        <Box className='benefit-label intertitle textcenter' alignContent='center'  >{lovelabel}</Box>
                      </Grid>

                    </Grid>
                    
                    <Grid container   alignItems='center' pt='40px'  >
                          
                          <Grid item md={7} xs={11} >
                              <Box display='flex' justifyContent='center' >
                                  <img src={loveimg} className='benefit-loveimg' ></img>
                              </Box>
                          </Grid>  

                          <Grid container md={5} xs={11} pt={{md:'0px',xs:'15px'}} pb={{md:'20px',xs:'0px'}} >
                                {loveitems.map( (item,i) => { return (
                                <> 
                                <Grid container   alignItems='start'  pb={{xs:'15px',md:'25px'}}>
                                    <Grid item xs={2}>
                                    <Box display='flex' justifyContent='center' >
                                          <img src={flagimg} className='benefit-funflag'  ></img>     
                                    </Box>
                                    </Grid>
                                    <Grid item xs={9} className='normal textjustified '>
                                      {item.txt}
                                    </Grid>
                                </Grid>
                                </>
                                )})}  
                          </Grid>
      

                    </Grid>

              </Grid>
             
                             

               
  
          </Grid>  
          
          <Grid item  >
            <div class='benefit-frisedn'></div>
            </Grid>    
       </Grid>
    </Box>

    </ThemeProvider>
    </>
  );
}

export default BenefitSection;