import React from 'react';
import './OriginSection.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';


function OriginSection({
sectionId,
title1,
title2,
eggImg,
nameTxt,
originLabel, originTxt,
definitionLabel,definitionTxt,
diminutiveLabel,diminutiveTxt,
exampleLabel,exampleTxt,
mototxt1,mototxt2,mototxt3,motoimg,
}) {




const theme = createTheme({
  components: {
    // Name of the component
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '0px',
          paddingRight:'0px',
          '@media (min-width: 600px)': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
});





  return (
    
    <>
    <ThemeProvider theme={theme}>
    <Box className='container' id={sectionId} >
       <Grid container direction='column'  >
          
          {/* title section */}
          <Grid container className='innercontainer'>
              

              <Grid container direction='column' className='title-box' 
                    alignItems='flex-start' justifyContent='center' xs={12} >
                  <Grid item >
                      <Box className={title1.css}>{title1.txt}</Box>    
                      
                  </Grid>
                  <Grid item>
                    <Box className={title2.css}>{title2.txt}</Box>                 
                  </Grid>
              
              
              
              </Grid>
          </Grid>

          {/* main content */}
          <Grid container className='origin-content-box'>
          
          <Grid container  className='innercontainer' 
              justifyContent='space-evenly' pt='50px'
              alignItems='center'  pb='50px'  >



                {/* egg image */}
                <Grid item xs={12} md={4} mb='25px'>
                  <Box display='flex' justifyContent='center' >
                    <img src={eggImg} className='origin-eggimg' ></img>
                    </Box>
                </Grid>
                <Grid item sm={1} xs={0} ></Grid>

                {/* texte */}
                <Grid item xs={12} md={7}>
                <Box display='flex' justifyContent='center'>
                <Grid container direction='column' rowSpacing={1.5} >
                    <Grid item>
                    <Box  className='intertitle' pb='40px' justifyContent={{xs:'center',md:'left'}} display='flex'  >{nameTxt}</Box>
                    </Grid>
                    <Grid item>
                      <Box  component='span'className='normalbold'>{originLabel}</Box>
                      <Box  component='span'className='normal'>{originTxt}</Box>
                    </Grid>
                    <Grid item>
                      <Box  component='span'className='normalbold'>{definitionLabel}</Box>
                      <Box  component='span'className='normal'>{definitionTxt}</Box>
                    </Grid>
                    <Grid item>
                      <Box  component='span'className='normalbold'>{diminutiveLabel}</Box>
                      <Box  component='span'className='normal'>{diminutiveTxt}</Box>
                    </Grid>
                    <Grid item>
                      <Box  component='span'className='normalbold'>{exampleLabel}</Box>
                      <Box  component='span'className='normal'>{exampleTxt}</Box>
                    </Grid>
                    </Grid>  
                    </Box>
          
                </Grid>
            
                    
          
          
          
          
          </Grid>
          
          {/* moto Block */}
          <Grid container  className='innercontainer' 
                  justifyContent='center' direction='row'
                  alignItems='center'  pb='100px' pt='20px' rowSpacing={5}>  

                    
                    <Grid item >
                        <Box  display='flex' className='intertitle textcenter'
                        alignContent='center' justifyContent='center'>
                          {mototxt1}
                        </Box>
                        <Box  display='flex' className='intertitle textcenter'
                        alignContent='center' justifyContent='center'>
                          {mototxt2}
                        </Box>
                        <Box  display='flex' className='intertitle textcenter'
                        alignContent='center' justifyContent='center'>
                          {mototxt3}
                        </Box>
                    </Grid>


                    <Grid item >
                        <Box display='flex' justifyContent='center'  width='100%'>

                          <img src={motoimg} className='origin-allzygimg'  ></img>     
                        </Box>  
                    </Grid>


          </Grid>



          </Grid>





       </Grid>
    </Box>

    </ThemeProvider>
    </>
  );
}

export default OriginSection;