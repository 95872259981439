import React from 'react';
import './RoadmapSection.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';



function RoadmapSection({
sectionId,
title1,
title2,
txt,
roadmapImg,

}) {


  return (
    
    <>

    <Box className='container noselect' id={sectionId} >
       <Grid container direction='column'  >
          
          {/* title section */}
          <Grid container className='innercontainer'>
              

              <Grid container direction='column' className='title-box' 
                    alignItems='flex-start' justifyContent='center' xs={12} >
                  <Grid item >
                      <Box className={title1.css}>{title1.txt}</Box>    
                      
                  </Grid>
                  <Grid item>
                    <Box className={title2.css}>{title2.txt}</Box>                 
                  </Grid>
              
              
              
              </Grid>
          </Grid>

          {/* main content */}
          <Grid container className='roadmap-content-box'>
          <Grid container  className='innercontainer' 
              justifyContent='space-evenly' pt='100px'
              alignItems='center'  pb='100px'  direction='row' >

                  {/* texte */}
                  <Grid item >
                      <Box display='flex' justifyContent='center' className='chapeau textjustified' pb='75px'>
                            {txt}
                      </Box>
                  </Grid>



                {/* egg image */}
                <Grid item >
                  <Box display='flex' justifyContent='center' >
                    <img src={roadmapImg} className='roadmap-img' ></img>
                    </Box>
                </Grid>
                
         
          
          </Grid>
          
          </Grid>
       </Grid>
    </Box>


    </>
  );
}

export default RoadmapSection;