import React from 'react';
import './EggybankSection.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';


function EggybankSection({
sectionId,
title1,
title2,
ebtitle,ebsubtitle,
ebitems,
}) {




const theme = createTheme({
  components: {
    // Name of the component
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '0px',
          paddingRight:'0px',
          '@media (min-width: 600px)': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
});





  return (
    
    <>
    <ThemeProvider theme={theme}>
    <Box className='container' id={sectionId} >
       <Grid container direction='column'  >
          
          {/* title section */}
          <Grid container className='innercontainer'>
              

              <Grid container direction='column' className='title-box' 
                    alignItems='flex-start' justifyContent='center' xs={12} >
                  <Grid item >
                      <Box className={title1.css}>{title1.txt}</Box>    
                      
                  </Grid>
                  <Grid item>
                    <Box className={title2.css}>{title2.txt}</Box>                 
                  </Grid>
              
              
              
              </Grid>
          </Grid>

          {/* main content */}
          <Grid container className='eggybank-content-box'>
          

              <Grid container  className='innercontainer' 
                    justifyContent='center' 
                    alignItems='center'  pb='50px' pt='0px' >  
              
              
                       

                  {/* Eggy items*/}                      
                  <Grid container justifyContent='space-evenly' direction='row' mt='75px' alignContent='center'  >
                  {ebitems.map( (item,i) => { return (
                               
                       <Grid container direction='row' width='320px' mb='60px' justifyContent='center' >
                          <Grid container  alignItems='start'  xs={12} mb='30px'>
                                <Grid item xs={1} >
                                <Box display='flex' justifyContent='center' className='benefit-ebnb'>
                                      {i+1}        
                              </Box>  
                                </Grid>  
                                <Grid item xs={10} pl='25px'>
                                <Box display='flex' justifyContent='center' className='normal '>
                                      {item.txt}        
                              </Box>  
                                </Grid>  
                          </Grid>  

                          <Grid item xs={12}>
                              { (i==2) ?
                              <Box display='flex' justifyContent='center' mt={{xs:'-100px', lg:'0px'}}>
                                          <img src={item.img} className='benefit-ebimg'  ></img>     
                              </Box> 
                              :
                              <Box display='flex' justifyContent='center'>
                                          <img src={item.img} className='benefit-ebimg'  ></img>     
                              </Box> 
                              }
                          
                          </Grid>        
                         
                        </Grid>  
                        
                                )})}             
                   </Grid>                    
 
 
 
              </Grid>  {/* Eggy Innercontainer */}
        
          </Grid>





       </Grid>
    </Box>

    </ThemeProvider>
    </>
  );
}

export default EggybankSection;