import * as React from "react";
import { useState } from "react";
import AnimateCC from "react-adobe-animate";
import './AnimSection.css';
import Box from '@mui/material/Box';

const AnimAdobe = () => {
      const [paused, setPaused] = useState(false);
      const [animationObject, getAnimationObject] = useState(null);
    const onClick = () => setPaused(!paused);

  
   //const preloadImg='anims/anim1/preloader-anim-une-zygs@2x.png';
 const preloadImg='anims/preload-oeuf-une.gif';
  // const preloadImg='anims/Anim10000x12/preloader-anim-10000-zygs@1x.png';

console.log(animationObject);

  return (
    <>
    <Box className='noselect anim-content-box' 
        mt='40px' display='flex'
        justifyContent='center' alignContent='center'>


        <Box className='' width={(animationObject) ? '100%' : '1%'}>
  
        <AnimateCC
              //animationName="anim10000zygsTEST2"
              animationName="animunezygs"
              //composition="CE9F7476D72E439C8C833B9160D95DAC"
              getAnimationObject={getAnimationObject}
              paused={paused}
      />
</Box>

      
    {(!animationObject) &&
         
              <img src={preloadImg} class='anim-preloadImg' ></img>
         
      }


    </Box>
    </>
  );
};

export default AnimAdobe;