import React from 'react';
import './TeamSection.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TwitterIcon from '@mui/icons-material/Twitter';
import {openInNewTab} from '../../utils/myLib';


function HomeSection({
sectionId,
title1,
title2,
twitterimg,
teammates,
}) {




const theme = createTheme({
  components: {
    // Name of the component
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '0px',
          paddingRight:'0px',
          '@media (min-width: 600px)': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
});

const onSocialClicked= (item) => {
  openInNewTab(item)
}





  return (
    
    <>
    <ThemeProvider theme={theme}>
    <Box className='container noselect' id={sectionId} >
       <Grid container direction='column'  >
          
          {/* title section */}
          <Grid container className='innercontainer' >
 

              <Grid container direction='column' className='title-box' 
                    alignItems='flex-start' justifyContent='center' xs={11} >
                  <Grid item >
                      <Box className={title1.css}>{title1.txt}</Box>    
                      
                  </Grid>
                  <Grid item>
                    <Box className={title2.css}>{title2.txt}</Box>                 
                  </Grid>
              
              
              
              </Grid>
          </Grid>

          {/* main content */}
          <Grid container  className='team-content-box'>
          <Grid container  className='innercontainer' 
              justifyContent='space-evenly' 
              alignItems='center'  pb='50px' pt='50px' >
          {teammates.map((mate,i) => { return (
             <>
            <Grid container direction='column'  alignItems='center' 
            justifyContent='space-around' 
            width='275px' pb='50px'>
           
                {/* image */}
                  <Grid item >
                    <Box display='flex'  justifyContent='center'>
                        <img src={mate.img} className='team-mateimg' ></img>
                    </Box>
                  </Grid>

                  {/* description */}
                  <Grid container direction='column'  alignItems='flex-start' 
                  justifyContent='flex-start'   height='120px'>
                      <Grid container alignItems='center'>
                          <Grid item className='team-matename' xs={10}>
                              {mate.name}
                          </Grid>
                          <Grid item  xs={2} >
                          { (mate.twitter != 'NA') &&
                          <Box display='flex'  justifyContent='center' className='pointer' onClick={(e)=>onSocialClicked(mate.twitter) }>
                            <TwitterIcon sx={{ color: 'white', fontSize: 30 }}  ></TwitterIcon>
                              
                          </Box>
                          }
                          </Grid>
                      </Grid>
                      <Grid item className='normalbold' >
                          {mate.title}
                      </Grid>
                      <Grid item className='normal'>
                        {mate.function}
                      </Grid>

                  </Grid>

                  {/* attributs */}
                  <Box className='attributs-box' bgcolor="white" width='100%' height='150px' padding='10px'  borderRadius='10px' >
                  <Grid container direction='column'  alignItems='center' 
                  justifyContent='center' >
                        {mate.attributs.map((attributs,i) => { return (
                          <>
                          <Grid container direction='row' ml='5px' mt='5px'>
                              <Grid item className='small'   xs={6}>{attributs.trait}</Grid>
                              <Grid item xs={6}>
                                {[0,1,2,3,4].map( (i) => { return (
                                 <> 
                                    {(i<attributs.star)  ?
                                    <img src='images/TEAM/yellow-star@2x.png' width='20px' ></img>
                                    :
                                    <img src='images/TEAM/grey-star@2x.png' width='20px' ></img>
                                }
                                </>
                                )})}  
                               </Grid>
                          
                            
                          </Grid>
                          
                          
                          </>
                          )})}
                  </Grid>

                  </Box>

            </Grid> 
            </>

           )})}
          
          
          
          </Grid>
          </Grid>
       </Grid>
    </Box>

    </ThemeProvider>
    </>
  );
}

export default HomeSection;