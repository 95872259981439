import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import './Navbar.css'
import {  NavHashLink } from 'react-router-hash-link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {openInNewTab} from '../../utils/myLib'

export default function NavBar({
    socialnetwork,
    mainmenu,
  }) {

   
    const [click, setClick] = useState(false)
    const [button, setButton] = useState(true)
    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false);
    const [anchorElNav, setAnchorElNav] = React.useState(null);

  
    useEffect(() => {
  
      }, []);

const handleOpenNavMenu = (event) => {
       setAnchorElNav(event.currentTarget);
};
const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

const onSocialClicked= (item) => {
    openInNewTab(item.address)
   
  
}



//  window.addEventListener('resize', showButton);
  


    return (
        <>
        <Box className='container noselect' >
            <Grid container direction='row'  alignItems='flex-end'  className={'navbar '}  
            justifyContent='center'  pt={{md:'10px',xs:'50px'}} pb='50px' pl={{md:'15px',xs:'0px'}}  >
                <Grid item xs={0.5}></Grid>
                <Grid Item xs={5.5}>
                <Link to='/'  onClick={closeMobileMenu}>
                        <img src='images/HEADER/logo-zygs.png'  className="navbar-logo"  />
                </Link>
                </Grid>

                {/* menu big */}
                 <Grid container xs={6}   direction='column' sx={{ display: { xs: "none", md: "flex" } }} 
                        
                        alignItems='flex-end'>

                        {/* social menu */}
                        <Grid container xs={12} justifyContent='flex-end' pb='20px'>
                        {socialnetwork.map( (item,i) => { return (
                                    <>
                                    <Grid item>
                                            <img src={item.img}  name={item.name} class="navbar-social pointer"  
                                            onClick={(e)=>onSocialClicked(item) } />
                                    </Grid>
                                    </>
                                    )})}

                        </Grid>
               
                        {/* menu itselef*/}
                        <Grid container xs={12} justifyContent='flex-end' pt='30px'>
                            {mainmenu.map( (item,i) => {
                                return (<>
                                <Grid item className='navbar-gridmenu-item ' >
                                    <NavHashLink smooth to={item.link} 
                                    className='navbar-menu-item  noir' 
                                    activeClassName="navbar-menu-item-active"
                                    onClick={closeMobileMenu}>
                                        {item.name}
                                    </NavHashLink>
                                </Grid>
                                {(i!=mainmenu.length-1) &&
                                    <Grid item>|</Grid>
                                }
                                </>
                                )})}
                        </Grid>
               
                </Grid> 
                {/* burger menu */}
                <Grid container xs={6} spacing={0.5} justifyContent='flex-end'
                sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                    <IconButton 
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                       >
                            <MenuIcon sx={{ fontSize : 40 }}/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                         anchorEl={anchorElNav}
                        anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left"
                        }}
                        keepMounted
                        transformOrigin={{vertical: "top",horizontal: "left"}}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{ display: { xs: "block", md: "none" }}}>

                            {mainmenu.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                      {/*  <Typography textAlign="center">{page.name}</Typography> */}
                                    <NavHashLink smooth to={page.link} 
                                          className='navbar-menu-item  noir' 
                                          activeClassName="navbar-menu-item-active">
                                        {page.name}
                                    </NavHashLink>  
                                </MenuItem>
                            ))}
                            
                      </Menu>    


                 </Grid>   

   
            </Grid>
        </Box>
        </>
    )
}
