import React from 'react';
import './MintSection.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {openInNewTab} from '../../utils/myLib';
import {datas} from '../../App-Data.js';


function TraitSection({
sectionId,
title1,
title2,
txt1,txt2,
}) {



  const onSocialClicked= (item) => {
    openInNewTab(item.address)
   
  
}


const theme = createTheme({
  components: {
    // Name of the component
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
          paddingRight:'0px',
          '@media (min-width: 600px)': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
});



  return (
    
    <>
    <ThemeProvider theme={theme}>
    <Box className='container textselected' id={sectionId} >
       <Grid container direction='column' >
          
          {/* title section */}
          <Grid container  className='innercontainer' >

              <Grid container direction='column' className='title-box' 
                    alignItems='flex-start' justifyContent='center' xs={11} >
                  <Grid item >
                      <Box className={title1.css}>{title1.txt}</Box>    
                      
                  </Grid>
                  <Grid item>
                    <Box className={title2.css}>{title2.txt}</Box>                 
                  </Grid>
              
              
              
              </Grid>
          </Grid>

          <Grid item  >
            <div class='mint-friseup'></div>
            </Grid>
          {/* main content */}
          <Grid container className='mint-content-box'>
           <Grid container  className='innercontainer' direction='row'
              justifyContent='center' 
              alignItems='center'  pb='100px' pt='100px' >
                  <Grid item xs={12}>
                      <Box display='flex'  justifyContent='center' 
                      alignItems='center' alignContent='center'>
                        <Box className='title-smallbold textcenter' width='400px' >{txt1}</Box>
                      </Box>    
                      
                  </Grid>
                  <Grid item xs={12} mt='75px'>
                    <Box  display='flex'  justifyContent='center'>
                    <Box className='chapeau textcenter'  text >{txt2}</Box>
                    </Box>                 
                  </Grid>

                  <Grid container xs={12} mt='40px' justifyContent='center' columnSpacing={4}>
                      <Grid item>
                                <img src={datas.socialnetwork[0].img}  name={datas.socialnetwork[0].name} class="pointer mint-social"  
                                 onClick={(e)=>onSocialClicked(datas.socialnetwork[0]) } />
                      </Grid>
                      <Grid item>
                                <img src={datas.socialnetwork[1].img}  name={datas.socialnetwork[1].name} class="pointer mint-social"  
                                 onClick={(e)=>onSocialClicked(datas.socialnetwork[1]) } />
                      </Grid>
                  </Grid>           
          </Grid>
          </Grid>  
          
          <Grid item  >
            <div class='mint-frisedn'></div>
            </Grid>    
       </Grid>
    </Box>

    </ThemeProvider>
    </>
  );
}

export default TraitSection;