import React from 'react';
import { useEffect, useState } from "react";

import AdobeAnim from '../../section/1-AnimSection/AnimSection';
import Anim10000 from '../../section/2-Anim10000Section/Anim10000Section';
import MintSection from '../../section/1-MintSection/MintSection';
import TraitSection from '../../section/2-TraitSection/TraitSection';
import BenefitSection from '../../section/3-BenefitSection/BenefitSection';
import RoadmapSection from '../../section/4-RoadmapSection/RoadmapSection';
import TeamSection from '../../section/4-TeamSection/TeamSection';
import FAQSection from '../../section/5-FaqSection/FaqSection';
import OriginSection from '../../section/2-OriginSection/OriginSection';
import EggybankSection from '../../section/6-EggybankSection/EggybankSection';
import TimeMachineSection from '../../section/7-TimemachineSection/TimeMachineSection';
import { teamsection,faqsection,originsection,traitsection,mintsection,benefitsection ,
    anim10000section,roadmapsection,eggybanksection,timemachinesection} from '../../App-Data';

const  Home = (props) => {
  const [saleStatus, setSaleStatus] = useState("");
  const [persoStatus, setPersoStatus] = useState(false);
  
  
  useEffect(() => {
      var {saleStatus} =  window['runConfig']; 
    setSaleStatus(saleStatus);
    }
  , [])
  
  


  
  
  return (
    <>
     <AdobeAnim></AdobeAnim> 
     <Anim10000  {...anim10000section} />
     <TraitSection {...traitsection} /> 
     <BenefitSection {...benefitsection} /> 
     <OriginSection {...originsection} /> 
     <MintSection {...mintsection} /> 
     <RoadmapSection {...roadmapsection} /> 
     <TeamSection {...teamsection} /> 
     <EggybankSection {...eggybanksection} /> 
     <FAQSection {...faqsection} />
     <TimeMachineSection {...timemachinesection} />
   

    </>
  );
}

export default Home;
