import React,{useEffect, useState }  from 'react';
import './styles/App.css';
import Home from './pages/1-HomePage/Home';
import MyAdmin from './pages/MyAdminPage/MyAdmin';
import { BrowserRouter as Router, Switch, Route,Redirect } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Login from './pages/LoginPage/Login';
import theme from './styles/theme.js';
import { ThemeProvider } from '@emotion/react';
import {datas} from './App-Data';




function App() {
  
  const [token, setToken] = useState(false);
  const [alone, setAlone] = useState();


  if(!token) {
    return <Login setToken={setToken} />
  }





  return (
    <ThemeProvider theme={theme}>
    <Router>
    <Navbar {...datas} />
      <Switch>
         <Route exact path='/home'  component={Home}  />
         <Route exact path='/admin'  component={MyAdmin}  />
         <Route render={() => <Redirect to="/home" />} />  

      </Switch>
{/*  <MyFooter />  */}
    </Router>
    </ThemeProvider>
  );
}

export default App;
