import { CastForEducationTwoTone, SwitchCameraTwoTone, YouTube } from "@mui/icons-material";



export const datas = {
     socialnetwork : [
    {address : 'https://twitter.com/ALLZYGS',img:'images/HEADER/twitter-zygs@2x.png', name : 'twitter'},
    {address : 'https://discord.com/invite/tBcT9tGFvv',img:'images/HEADER/discord-zygs@2x.png', name : 'discord'},
  /*  {address : 'https://opensea.io/collection/cryptomories',img:'images/HEADER/opensea-zygs@2x.png', name : 'opensea'},*/
     ],
     mainmenu : [
        { name:'COLLECTION', link : '/home#collection'},
        { name:'BENEFITS', link : '/home#benefits'},
        { name:'ROAD MAP', link : '/home#roadmap'},
        { name:'MINT', link : '/home#mint'},
        { name:'TEAM', link : '/home#team'},
        { name:'FAQ', link : '/home#faq'},
      
    ], 
};


export const mintsection = {
    sectionId : 'mint',
    title1 :{txt :  'grab', css:'title-smallabove'},
    title2 : {txt : 'yours',css :'title-big'},
    txt1:"Don't miss the mint day!",
    txt2 :"FOLLOW US ON TWITTER AND DISCORD",

}


export const traitsection = {
    sectionId : 'trait',
    title1 :{txt :  '560', css:'title-big'},
    title2 : {txt : 'traits',css :'title-small'},
    traits : [
        {id: 0,
            trait:'TYPES',
            icon:'images/TRAITS/types@2x.png',
            number:4,
            },

        {id: 1,
        trait:'BODY',
        icon:'images/TRAITS/gender@2x.png',
        number:2,
        },
        {id: 2,
            trait:'SKINS',
            icon:'images/TRAITS/skins@2x.png',
            number:4,
        },
        {id: 3,
            trait:'HAIRS',
            icon:'images/TRAITS/hairs@2x.png',
            number:60,
        },
        {id: 4,
            trait:'EYES',
            icon:'images/TRAITS/eyes@2x.png',
            number:12,
        },
        {id: 5,
            trait:'GLASSES',
            icon:'images/TRAITS/glasses@2x.png',
            number:28,
            },
        {id: 6,
                trait:'MOUTHS',
                icon:'images/TRAITS/mouths@2x.png',
                number:26,
            },
            {id: 7,
                trait:'HATS',
                icon:'images/TRAITS/hats@2x.png',
                number:80,
            },
             {id: 8,
                trait:'OUTFITS',
                icon:'images/TRAITS/suits@2x.png',
                number:28,
                },
            {id: 9,
                trait:'OVERTOPS',
                icon:'images/TRAITS/jackets@2x.png',
                number:50,
            },
            {id: 11,
                trait:'TOPS',
                icon:'images/TRAITS/sweats@2x.png',
                number:68,
            },
            {id: 12,
                trait:'BOTTOMS',
                icon:'images/TRAITS/trousers@2x.png',
                number:45,
            },
            {id: 13,
                trait:'UNDERWEARS',
                icon:'images/TRAITS/underwear@2x.png',
                number:41,
            },
            {id: 22,
                trait:'JEWELRY',
                icon:'images/TRAITS/jewelry@2x.png',
                number:34,
            },
            {id: 23,
                trait:'ACCESSORIES',
                icon:'images/TRAITS/accessories@2x.png',
                number:18,
            },
            {id: 25,
                trait:'BACKGROUNDS',
                icon:'images/TRAITS/backgrounds@2x.png',
                number:14,
            },

            {id: 26,
                trait:'UNIQUES',
                icon:'images/TRAITS/uniques@2x.png',
                number:10,
            },
    ]
}

export const roadmapsection = {
    sectionId : 'roadmap',
    title1 :{txt :  'road', css:'title-smallabove'},
    title2 : {txt : 'map',css :'title-big'},
    roadmapImg : 'images/ROADMAP/infographie-all-zygs@2x.png',
    txt : 'Do you know that old Chinese proverb that says "The one who wants to go too fast ends up in omelette" ? \
    All Zygs are eggs like you and me. And neither you nor us want to end up in an omelet. \
    So, we will move forward, step by step. But with a great desire to propel \
    All Zygs to the moon. And believe us, we will get there! But not alone. With you ! \
    We are all Zygs! ',
}



export const teamsection = {
    sectionId : 'team',
    title1 :{txt :  'the', css:'title-smallabove'},
    title2 : {txt : 'team',css :'title-big'},
    twitterimg:'images/TEAM/twitter-team@2x.png',
    teammates : [
        {   name:'oneybee',
            title:'ALL ZYGS father',
            function:'Graphic designer',
            img:'images/TEAM/zyg-oneybee@2x.png',
            twitter:' https://twitter.com/oneybee_twit',
            attributs : [
                {trait:'Illustrator',star:5},
                {trait:'Girls',star:3},
                {trait:'Surf',star:0},
                {trait: 'NFTs',star:3},
            ]
        },
        {   name:'bignenet',
            title:'ALL ZYGS godfather',
            function:'Developper',
            img:'images/TEAM/zyg-bignenet@2x.png',
            twitter:'NA',
            attributs : [
                {trait:'Web3 & React',star:5},
                {trait:'Girls',star:1},
                {trait:'Surf',star:3},
                {trait: 'NFTs',star:5},
            ]
        },
        {   name:'iwwon',
        title:'ALL ZYGS tutor',
        function:'Artist - Cryptomories & LosSamos creator.',
        img:'images/TEAM/zyg-iwwon@2x.png',
        twitter:'https://twitter.com/iwwon_official',
        attributs : [
            {trait:'Photoshop',star:5},
            {trait:'Girls',star:5},
            {trait:'Surf',star:5},
            {trait: 'NFTs',star:5},
            
        ]
    }, {   name:'oneybee',
    title:'ALL ZYGS father',
    function:'Graphic designer',
    img:'images/TEAM/zyg-oneybee@2x.png',
    twitter:'https://twitter.com/iwwon_official',
    attributs : [
        {trait:'Illustrator',star:5},
        {trait:'Girls',star:3},
        {trait:'Surf',star:0},
        {trait: 'NFTs',star:3},
    ]
},
{   name:'bignenet',
    title:'ALL ZYGS godfather',
    function:'Developper',
    img:'images/TEAM/zyg-bignenet@2x.png',
    twitter:'https://twitter.com/iwwon_official',
    attributs : [
        {trait:'Web3 & React',star:5},
        {trait:'Girls',star:1},
        {trait:'Surf',star:3},
        {trait: 'NFTs',star:5},
    ]
},
{   name:'iwwon',
title:'ALL ZYGS tutor',
function:'Artist - Cryptomories & LosSamos creator.',
img:'images/TEAM/zyg-iwwon@2x.png',
twitter:'https://twitter.com/iwwon_official',
attributs : [
    {trait:'Photoshop',star:5},
    {trait:'Girls',star:5},
    {trait:'Surf',star:5},
    {trait: 'NFTs',star:5},
    
]
}, {   name:'oneybee',
title:'ALL ZYGS father',
function:'Graphic designer',
img:'images/TEAM/zyg-oneybee@2x.png',
twitter:'https://twitter.com/iwwon_official',
attributs : [
    {trait:'Illustrator',star:5},
    {trait:'Girls',star:3},
    {trait:'Surf',star:0},
    {trait: 'NFTs',star:3},
]
},
{   name:'bignenet',
title:'ALL ZYGS godfather',
function:'Developper',
img:'images/TEAM/zyg-bignenet@2x.png',
twitter:'https://twitter.com/iwwon_official',
attributs : [
    {trait:'Web3 & React',star:5},
    {trait:'Girls',star:1},
    {trait:'Surf',star:3},
    {trait: 'NFTs',star:5},
]
},
{   name:'iwwon',
title:'ALL ZYGS tutor',
function:'Artist - Cryptomories & LosSamos creator.',
img:'images/TEAM/zyg-iwwon@2x.png',
twitter:'https://twitter.com/iwwon_official',
attributs : [
{trait:'Photoshop',star:5},
{trait:'Girls',star:5},
{trait:'Surf',star:5},
{trait: 'NFTs',star:5},

]
},
    ]
}


export const faqsection = {
    sectionId : 'faq',
    title1 :{txt :  'what the ', css:'title-small'},
    title2 : {txt : 'faq',css :'title-big'},
    questions : [
        {id: 1,
        question:'Who are the All ZYGS?',
         answer : "ZYGS are fertilized soft boiled eggs, more or less cracked. \
         They are white, yellow, black or brown. Women, men or queer. \
        Cute or grumpy. Bald or hairy. Smart or stupid. Straight or gay. \
        Geeks or babacools. Orthodox or unorthodox. They are like humanity. \
        All different. All unique. All more or less cracked. It's their charm. \
        \"Blessed are the cracked for they let in the light (Groucho Marx).\""
        },
        {id: 2,
            question:'Is each ZYG unique?',
             answer : "Yes. Each of the 9,990 ZYGS was randomly crafted from over 560 traits, 10 are handmade. \
             No ZYG is the same. Some may look alike, but none are identical. \
             Just like all of us on this planet. You will therefore be assured of having a unique ZYG. \
             And some are even rarer than rare.  \
             If you come across them, it's bingo! It's the lottery of life..."
        },
        {id: 3,
                question:'What is an NFT?',
                 answer : "NFT stands for Non Fungible Token in nerd parlance. \
                 To put it simply, it’s a unique digital creation, the exclusive property of which \
                 is certified by an unforgeable digital contract."
                 /*                /* answer:'As Gogfather said " Digital art is not a joke, it is a testimony of our time, and we honored to be cavemen of the 2020 s, \
                         digitally painting the walls of the crypto-cave"',*/
        },
        {id: 4,
            question:'What’s special about these NFTs?',
             answer : "In every ZYG there is humanity. Logic, we are humanists. \
             We have therefore decided to donate 20% of secondary sales royalties to associations or actions \
             defending our humanist values in real life. The rise of NFTs has shown that digital \
             creations have value, regardless of their materialization, creating a new market \
             and a new source of enrichment. We want it to benefit everyone. Not just a few"
             
        },
        {id: 5,
            question:'When will ZYGS go on sale?',
             answer : "Very soon ! We will announce the mint day on social networks. \
             Follow us on Discord and Twitter."
        },
        {id: 6,
            question:'Can I choose my ZYG?',
             answer : "In the same way that you are a unique creation, the result of the random \
             encounter between a friendly egg and a punchy sperm, each ZYG is unique, randomly \
             created and randomly distributed at mint time. It's called the lottery of life. \
             On the other hand, you can still buy the one you love on any NFT exchange platform."
        },
        {id: 7,
            question:'Could I print my ZYG?',
             answer : "Yes of course ! On a T-shirt, a shower curtain, a mug, \
             a poster, a quilt,... it's up to you ! For any purchase of a ZYG we offer \
             you the possibility to download it in HD. Then you can print it wherever you want. \
             There are hundreds of possibilities on POD (Print On Demand) platforms."
        },
        {id: 8,
            question:'What about IP rights ?',
             answer : 'Your ZYG is who you are, you can do whatever you want \
             with it provided  you respect our values. Be creative and have fun!'
        },
    ]
}

export const originsection = {
    sectionId : 'origin',
    title1 :{txt :  'one', css:'title-smallabove'},
    title2 : {txt : 'origin',css :'title-big'},
    eggImg : 'images/ORIGIN/ALL-ZYGS-anim-making-of.gif',
    nameTxt : 'Zygote',
    originLabel : 'Etymology: ',
    originTxt : ' from Ancient Greek zygotós "joined"',
    definitionLabel :'Definition: ',
    definitionTxt : 'refers to a fertilized egg, resulting from the union between a male and a female, giving rise to a unique living organism.',
    diminutiveLabel : 'Diminutive: ',
    diminutiveTxt : 'ZYG',
    exampleLabel : 'Example: ',
    exampleTxt :'"Never met a zyg as awesome as you"',
    philotxt:<div>At the beginning of our life, we , humans, are all zygotes, we are all the same<br/>
    And yet, we kill for a difference of color or a difference of idea.<br/>
    And yet, some of us live well and go to university, others live to survive and have no access to education.<br/>
    And yet, we are ALL ZYGS, we are all the same.</div>,
        mototxt1 : 'all the same',
        mototxt2 : 'all different',
        mototxt3 : 'ALL ZYGS',
        motoimg : 'images/BENEFITS/we-are-all-zygs2x.png',
}

export const benefitsection = {
    sectionId : 'benefits',
    title1 :{txt :  'here are', css:'title-smallabove'},
    title2 : {txt : 'ALL ZYGS',css :'title-big'},
    title3 : {txt : 'slightly cracked eggs collection',css :'title-small'},
    teasertxt : "Fall for the funniest and coolest collection of NFTs in the universe and the metaverse!",
    teaserauthor: "John Doe",
    introtxt : "ALL  ZYGS  is  a  unique  collection  of  10,000  soft-boiled  eggs, \
               more or less cracked, all unique, all different, just like humanity. \
               Each ZYG was released as a collectible ERC-721 non-fungible token (NFT),  \
               stored on the Ethereum blockchain, with metafiles and images frozen on IPFS.",
    funlabel : 'have fun',
    funitems : [
        {funid:1,txt:"Become the sole owner of a unique ZYG in the world."},
        {funid:2,txt:"Print it in a museum quality on whatever you want."},
        {funid:3,txt:"Watch your NFT increase in value and resell it at any time."},
        {funid:4,txt:"Watch your NFT prosper quietly or resell it at anytime."},
        {funid:5,txt:"Enjoy the power of an extended family including Cryptomories and Spiky Space Fish."},
        
    ],
    lovelabel: 'share love',
    loveitems : [
        {loveid:1,txt:"Join a humanist and active community with real life positive action."},
        {loveid:2,txt:"20% of secondary sales  royalties will go into \
                    our EGGY BANK. Whenever the EGGY BANK is full, we will give our funds to educational associations \
                    and to those who share our humanist values. \
                    "},
        {loveid:3,txt:"And tomorrow, be part of a DAO that will decide the fund allocations."},
    ],
    nftimg :'images/BENEFITS/big-label-nft@2x.png',
    flagimg : 'images/BENEFITS/coche@2x.png',
    fungif : 'images/anim/anim-benefits-500.gif',
    loveimg : 'images/BENEFITS/zygs-band.png',
    mototxt : 'we are ALL ZYGS!',
    motoimg : 'images/BENEFITS/we-are-all-zygs2x.png',
    ebtitle: 'eggy bank',
    ebsubtitle : 'how does it work',
    ebitems : [
        {txt: '20% of secondary sales royalties goes automatically into an EGGY BANK egg.',
        img : 'images/BENEFITS/eggy-bank-1@2x.png',},
        {txt:'One egg has a capacity of 1 ETH. When an egg is full, it is wrapped and boxed.',
         img : 'images/BENEFITS/eggy-bank-2@2x.png',},
        {txt : 'Once the 6 eggs box is filled, the collected amount is donated to selected actions & associations.',
         img: 'images/BENEFITS/eggy-bank-3@2x.png'}
        ],
    philotxt:<div id='philo'>At the very beginning of our life, we , humans, are all zygotes, we are all the same<br/>
        And yet, we kill for a difference of color or a difference of idea<br/>
        And yet, some of us live well and go to university, others live to survive and have no access to education<br/>
        And yet, we are ALL ZYGS, we are all the same</div>,
}


export const anim10000section = {
    sectionId : 'collection',
    title1 :{txt :  '10,000', css:'title-big'},
    title2 : {txt : 'soft-boiled eggs',css :'title-small'},
}

export const eggybanksection = {
    sectionId : 'eggybank',
    title1 :{txt :  'how works the', css:'title-smallabove'},
    title2 : {txt : 'eggy bank',css :'title-big'},
    eggImg : 'images/ORIGIN/ALL-ZYGS-anim-making-of.gif',
    ebtitle: 'eggy bank',
    ebsubtitle : 'how does it work',
    ebitems : [
        {txt: '20% of secondary sales royalties goes automatically into an EGGY BANK egg.',
        img : 'images/BENEFITS/eggy-bank-1@2x.png',},
        {txt:'One egg has a capacity of 1 ETH. When an egg is full, it is wrapped and boxed.',
         img : 'images/BENEFITS/eggy-bank-2@2x.png',},
        {txt : 'Once the 6 eggs box is filled, the collected amount is donated to selected actions & associations.',
         img: 'images/BENEFITS/eggy-bank-3@2x.png'}
        ], 
}

export const timemachinesection = {
    sectionId : 'timemachine',
    title1 :{txt :  '', css:'title-smallabove'},
    title2 : {txt : '',css :'title-big'},
    timemachineImg : 'images/FOOTER/ALL-ZYGS-hangar.png',
}

