import React from 'react';
import { useState } from "react";
import './FaqSection.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Paper from '@mui/material/Paper';
import MuiAccordion from '@mui/material/Accordion';

function FaqSection({
sectionId,
title1,
title2,
questions,
}) {


  const [expanded, setExpanded] = useState(false);


const theme = createTheme({
  components: {
    // Name of the component
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
          paddingRight:'0px',
          '@media (min-width: 600px)': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
});

const handleChange = (panel) => (event, isExpanded) => {
  setExpanded(isExpanded ? panel : false);
};


  return (
    
    <>
    <ThemeProvider theme={theme}>
    <Box className='container noselect' id={sectionId} >
       <Grid container direction='column'  >
          
          {/* title section */}
          <Grid container className='innercontainer'>

              <Grid container direction='column' className='title-box' 
                    alignItems='flex-start' justifyContent='center' xs={11} >
                  <Grid item >
                      <Box className={title1.css}>{title1.txt}</Box>    
                      
                  </Grid>
                  <Grid item>
                    <Box className={title2.css}>{title2.txt}</Box>                 
                  </Grid>
              
              
              
              </Grid>
          </Grid>

          {/* main content */}
          <Grid container className='innercontainer faq-content-box' 
              justifyContent='center' alignItems='center'>
          
          <Grid container   
              justifyContent='center' rowSpacing={2} spacing={2} 
              alignItems='center'  pb='50px' pt='0px' >

              {questions.map( (question,i) => { return (
              <> 
                <Grid item width={{xs:'100%', sm:'100%' ,xl:'100%'}} >
                  <Accordion elevation={0}  sx={{backgroundColor:'#89d994',borderRadius:'25px' }}  
                   expanded={expanded === 'panel'+i} onChange={handleChange('panel'+i)}
                    >
                  <AccordionSummary  expandIcon={<ExpandMoreIcon />} 
                            sx={{backgroundColor:'#89d994', borderRadius:'25px',pt:'5px',pb:'5px'}} 
                            aria-controls="panel1a-content"     id="panel1a-header"  >
                   <Box  className='faq-question' pl={{sm:'20px',xs:'0px'}} pr={{sm:'25px',xs:'0px'}}>{question.question}</Box>
                  </AccordionSummary>
    
                <AccordionDetails  sx={{backgroundColor:'89d994f', mt:'5px', borderRadius:'0px 0px 5px 5px'}}>
                    <Box className='faq-answer' pl={{sm:'20px',xs:'0px'}} pr={{sm:'25px',xs:'0px'}}>{question.answer}</Box>
                </AccordionDetails>
                </Accordion>
                                    
                   </Grid>                                     
              </>
              )})}  

          </Grid>
          </Grid>
       </Grid>
    </Box>

    </ThemeProvider>
    </>
  );
}

export default FaqSection;