import * as React from "react";
import { useState,useTheme } from "react";
import { Image} from 'react';
import AnimateCC from "react-adobe-animate";
import './Anim10000Section.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from "@mui/material/useMediaQuery";

function AnimAdobe10000({
  sectionId,
  title1,title2
}) {

  const [paused, setPaused] = useState(false);
  const [animationObject, getAnimationObject] = useState(null);
  const onClick = () => setPaused(!paused);




 // const preloadImg='anims/anim1/preloader-anim-une-zygs@2x.png';
 //const preloadImg='anims/Anim10000x12/preloader-anim-10000-zygs@1x.png';
 const preloadImg='anims/preload-oeuf-une.gif';

 const gifImagages=[
   'images/anim/1.gif',
   'images/anim/2.gif',
   'images/anim/3.gif',
   'images/anim/4.gif',
   'images/anim/5.gif',
   'images/anim/6.gif',
   'images/anim/7.gif',
   'images/anim/8.gif',
   'images/anim/9.gif',
   'images/anim/10.gif',
   'images/anim/11.gif',
   'images/anim/12.gif',
   'images/anim/13.gif',
   'images/anim/14.gif',
   'images/anim/15.gif',
 ];
  console.log(animationObject);

  function GetNbOfGif () {
    
     return (useMediaQuery('(min-width:600px)')?15:9);
  }


  return (
    <>
    <Box className='container noselect ' 
        mt='40px' display='flex'
        justifyContent='center' alignContent='center'>

          <Grid container direction='column' >
            
            {/* title section */}
            <Grid container  className='innercontainer' >

                <Grid container direction='column' className='title-box' 
                      alignItems='flex-start' justifyContent='center' xs={12} >
                    <Grid item >
                        <Box className={title1.css}>{title1.txt}</Box>    
                        
                    </Grid>
                    
                    <Grid item>
                      <Box className={title2.css} >{title2.txt}</Box>                 
                    </Grid>
                    


                </Grid>
            </Grid>
          </Grid>
    </Box>

    <Box className='' width='100%'>

          <Grid container >
          
          {gifImagages.filter((trait,i) => i < GetNbOfGif()).map( (trait,i) => { return (
 
          <Grid item xs={4} sm={2.4} >
          <img src={gifImagages[i]} class='anim-preloadImg' ></img>
          </Grid>    
            
            )})}    
            
            
            </Grid>
        </Box>


    {(!true) &&
         
              <img src={preloadImg} class='anim-preloadImg' ></img>
         
      }



    </>
  );
};

export default AnimAdobe10000;