import * as React from "react";
import { useState,useTheme } from "react";
import { Image} from 'react';
import AnimateCC from "react-adobe-animate";
import './TimeMachineSection.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from "@mui/material/useMediaQuery";

function TimemachineSection({
  sectionId,
  title1,title2,
  timemachineImg,
}) {

  const [paused, setPaused] = useState(false);
  const [animationObject, getAnimationObject] = useState(null);
  const onClick = () => setPaused(!paused);




  return (
    <>
   

    <Box className='' width='100%' paddingTop='60px'>

          <Grid container >
          
         
 
          <Grid item xs={12} sm={12} >
          <img src={timemachineImg} class='timemachine-img' ></img>
          </Grid>    
            
                  
            
            </Grid>
    </Box>
  

    </>
  );
};

export default TimemachineSection;